$color-dark-3: #676767;
$color-dark-2: #545454;
$color-dark-1: #363636;
$color-dark: #161616;
$text-dark: rgba(255,255,255,0.7);

$color-light: #F8F8F8;
$color-light-1: #f0f0f0;
$color-light-2: #E5E5E5;
$color-light-3: #D4D4D4;

$color-accent-dark: #F2B9C4;
$color-accent: #B14E7F;

$gradient-radial: radial-gradient(100% 813.49% at 100% 0%, #F2B9C4 0%, #B14E7F 50%, #823575 100%);
$gradient-linear: linear-gradient(180deg, #F2B9C4 0%, #B14E7F 50%, #823575 100%);
