@import "typography";
@import "buttons";
@import "colors";

$size-md: 768px;

.shadow-text {
  text-shadow: 2px 2px 5px rgba(0,0,0,0.4);
}

.white-text {
  color: white !important;
}

.abs-center {
  position: absolute;
  margin-left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.center {
  margin-left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.horiz-center {
  margin-left: 50%;
  transform: translateX(-50%);
}

.seperator {
  margin: 20px 0 20px 50%;
  height: 3px;
  width: 30px;
  transform: translateX(-50%);
  background-color: white;
}

.slice {
  height: 300px;
  overflow: hidden;
}
