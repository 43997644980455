h1, .mat-typography h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 48px;
  margin: 0 0 8px;
  padding: 0;
  line-height: initial;
}
h2, .mat-typography h2 {
  font-family: 'Lato', sans-serif;
  font-size: 36px;
  margin: 0 0 8px;
  padding: 0;
  line-height: initial;
}
h3, .mat-typography h3 {
  font-family: 'Lato', sans-serif;
  font-size: 24px;
  margin: 0 0 8px;
  padding: 0;
  line-height: initial;
}
h4, .mat-typography h4 {
  font-family: 'PT Sans', sans-serif;
  font-size: 20px;
  margin: 0;
  padding: 0;
  line-height: initial;
}
h5, .mat-typography h5 {
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  margin: 0;
  padding: 0;
  line-height: initial;
}
h6, .mat-typography h6 {
  font-family: 'PT Sans', sans-serif;
  font-size: 18px;
  margin: 0;
  padding: 0;
  line-height: initial;
}
p, .mat-typography p, button {
  font-family: 'PT Sans', sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: initial;
}

p:not(:last-child) {
  margin-bottom: 12px;
}

.code {
  font-family: "JetBrains Mono", monospace;
}
