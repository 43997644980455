// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mat-yolol-light: (
  50: #f3e6f0,
  100: #e2bfdc,
  200: #cf95c5,
  300: #ba6dae,
  400: #ab509d,
  500: #9b378d,
  600: #8f3287,
  700: #7f2b7f,
  800: #702776,
  900: #551f65,
  A100: #e2bfdc,
  A200: #cf95c5,
  A400: #ab509d,
  A700: #7f2b7f,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

$yolol-primary: mat-palette($mat-yolol-light);
$yolol-accent: mat-palette($mat-yolol-light);

// The warn palette is optional (defaults to red).
$yolol-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$yolol-theme: mat-light-theme($yolol-primary, $yolol-accent, $yolol-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($yolol-theme);


//custom addin for syntax hightlighting (to avoid angular weirdness)
@import "app/components/editor/syntax-highlighting.scss";



/* You can add global styles to this file, and also import other style files */

@import "~bootstrap/dist/css/bootstrap.css";
@import "assets/scss/shared";
@import "assets/scss/mixins";

@font-face {
  font-family: 'JetBrains Mono';
  src: url(assets/fonts/JetBrainsMono-Regular.ttf) format('truetype');
}

html, body {
  height: 100%;
}
body {
  margin: 0; font-family: 'PT Sans', 'Helvetica Neue', sans-serif;
}
p a, span a, div a:not(.logo-bg), section a {
  z-index: 2;
  position: relative;
  color: inherit;
  text-decoration: none;
  transition-duration: .3s;
  &:not(.icon-link) {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 2px;
      width: 100%;
      background: $gradient-radial;
      transform: translateX(-50%);
      transition-duration: .1s;
      z-index: -1;
    }
    &:hover {
      color: white;
      &:before {
        height: calc( 100% + 6px );
        width: calc( 100% + 10px );
        bottom: -4px;
        border-radius: 8px;
      }
    }
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}
.pointer-events-none {
  pointer-events: none;
}
.flow-column {
  flex-flow: column;
}
.mat-card {
  z-index: 2;
  position: relative;
  border: 1px solid black;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0 !important;
  box-shadow: none !important;
  &:before {
    z-index: -1;
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    height: 100%;
    width: 100%;
    background: $gradient-radial;
  }
  &:after {
    z-index: -1;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: white;
    transition-duration: .3s;
  }
  &.dark {
    &:after {
      background: $color-dark-1;
    }
  }
}
.mat-card-header {
  font-family: 'PT Sans', sans-serif;
  font-size: 20px;
  margin: 0 0 8px;
  line-height: initial;
}
.mat-menu-panel {
  transition-duration: .3s;
  &.dark {
    background-color: $color-dark-1;
    .mat-menu-item {
      color: $text-dark;
    }
  }
}

div .code-inline {
  display: inline-block;
  padding: 1px 5px;
  width: fit-content;
  font-family: "JetBrains Mono", monospace;
  color: rgba(0,0,0,0.7);
  background-color: rgba(0,0,0,0.1);
}
div.dark .code-inline {
  color: $text-dark;
  background-color: rgba(255,255,255,0.1);
}
div .code-block {
  overflow-x: auto;
  display: block;
  position: relative;
  white-space: pre;
  padding: 10px 13px;
  margin: 20px 0;
  width: 100%;
  color: rgba(0,0,0,0.7);
  font-family: "JetBrains Mono", monospace;
  background-color: rgba(0,0,0,0.05);
  @media only screen and (min-width: 768px) {
    padding: 10px 13px 10px 26px;
    &:after {
      content: 'CODE';
      position: absolute;
      top: 50%;
      height: fit-content;
      left: 0;
      width: fit-content;
      font-family: "JetBrains Mono", monospace;
      font-size: 10px;
      color: black;
      transform: translateY(-50%) rotate(-90deg);
    }
  }
}
div.dark .code-block {
  color: $text-dark;
  background-color: $color-dark-1;
  border-left: 3px solid $color-light-3;
  &:after {
    color: $text-dark;
  }
}
